import React from "react";
import { useNavigate } from "react-router-dom";

import { Stack, Box, Typography, Paper } from "@mui/material";
import { ThumbsUpDown, PhoneAndroid, Business } from "@mui/icons-material";

import { Container, Row, Col, Visitante } from "../components";

import pintoImage from "../assets/pintor-pintando-a-parede.png";
import pintoCosta from "../assets/Pintor-Costas.jpg";

//import BANNER1 from "../assets/Banner_Home_1.png";
//import BANNER2 from "../assets/Banner_Home_2.png";
import BANNER3 from "../assets/BANNER_01_HOME_DESKTOP_2025.jpg";
import BANNER4 from "../assets/Banner_Home_4.png";

const Home = () => {
  const navigate = useNavigate();

  const onNavigate = (to) => {
    window.scrollTo(0, 0);
    navigate(to);
  };

  const obra = [
    {
      title: "Insira seu CEP e confira os pintores da região",
      icon: <Business sx={{ fontSize: 60 }} color="secondary" />,
    },
    {
      title:
        "Veja fotos e vídeos dos trabalhos já realizados, além de avaliações de outros clientes.",
      icon: <PhoneAndroid sx={{ fontSize: 60 }} color="secondary" />,
    },
    {
      title:
        "Gostou, escolheu e quer entrar em contato? Faça seu cadastro e fale com o pintor agora mesmo!",
      icon: <ThumbsUpDown sx={{ fontSize: 60 }} color="secondary" />,
    },
  ];

  return (
    <Container imgs={[BANNER3, BANNER4]}>
      <Visitante />

      <Row
        justifyContent="center"
        mt={10}
        sx={{ display: { xs: "flex", md: "none" } }}
      >
        <Col xs={12} mt={6} mb={2}>
          <Stack
            direction={"column"}
            spacing={2}
            sx={{ position: "relative" }}
            pt={2}
          >
            <Stack justifyContent="center" alignItems={"center"}>
              <Box ml={3} sx={styles.boxEncontrePintor2}>
                <Box
                  sx={{
                    background: "#5BD3FF",
                    width: "200px",
                    height: "200px",
                    borderRadius: "100%",
                    backgroundImage: `url(${pintoCosta})`,
                    backgroundSize: "cover",
                  }}
                />
              </Box>
            </Stack>

            <Box
              width={"100%"}
              p="10px"
              pt={{ xs: 14, md: 2 }}
              textAlign="center"
            >
              <Typography
                color="secondary"
                variant="h3"
                fontSize={28}
                fontStyle="italic"
                fontWeight={700}
              >
                Ache o melhor pintor para sua obra!
              </Typography>
            </Box>
          </Stack>
        </Col>
        <Col xs={10}>
          <Stack direction={"column"} spacing={4} sx={{ position: "relative" }}>
            {obra.map((item) => (
              <Paper elevation={5} sx={styles.boxFotoPintor}>
                <Stack justifyContent="space-around" mt={3} spacing={2}>
                  <Stack justifyContent="center" alignItems="center">
                    {item.icon}
                  </Stack>
                  <Typography variant="body1" fontSize={14} mt={3}>
                    {item.title}
                  </Typography>
                </Stack>
              </Paper>
            ))}
          </Stack>
        </Col>
      </Row>

      <Row justifyContent="center" sx={{ display: { xs: "none", md: "flex" } }}>
        <Col xs={12} sx={styles.boxCadastro} mt={6}>
          <Stack
            direction={"row"}
            spacing={2}
            sx={{ position: "relative" }}
            pt={2}
          >
            <Box width={"100%"} pl="20px" textAlign="end">
              <Typography
                color="secondary"
                variant="h3"
                fontSize={28}
                fontStyle="italic"
                fontWeight={700}
              >
                Ache o melhor pintor para sua obra!
              </Typography>
              <Typography
                color="primary"
                variant="h3"
                fontSize={28}
                fontStyle="italic"
              >
                Aqui vocẽ encontra o <br /> pintor mais próximo de você.
              </Typography>
            </Box>
            <Stack width="300px" justifyContent="center" alignItems="start">
              <Box ml={3} sx={styles.boxEncontrePintor}>
                <Box
                  sx={{
                    background: "#5BD3FF",
                    width: "180px",
                    height: "180px",
                    borderRadius: "100%",
                    backgroundImage: `url(${pintoCosta})`,
                    backgroundSize: "cover",
                  }}
                />
              </Box>
            </Stack>
          </Stack>
        </Col>
        <Col xs={10}>
          <Stack
            direction={"row"}
            spacing={4}
            sx={{ position: "relative", top: -108 }}
          >
            {obra.map((item) => (
              <Paper elevation={5} sx={styles.boxFotoPintor}>
                <Row justifyContent="space-around" mt={3} spacing={2}>
                  <Col
                    md={10}
                    key={item}
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Stack
                      sx={styles.boxPintor}
                      justifyContent="center"
                      alignItems="center"
                      mb={1}
                    >
                      {item.icon}
                    </Stack>
                    <Typography variant="body1" fontSize={14} mt={6}>
                      {item.title}
                    </Typography>
                  </Col>
                </Row>
              </Paper>
            ))}
          </Stack>
        </Col>
      </Row>

      {/* <Row
        justifyContent="center"
        sx={{ display: { xs: "block", md: "flex" } }}
        paddingBottom="2rem"
      >
        <Col xs={12}>
          <Typography
            color="secondary"
            variant="h3"
            fontSize={28}
            fontStyle="italic"
            fontWeight={700}
          >
            Quem usa, recomenda{" "}
          </Typography>
        </Col>
        {[
          {
            img: "",
            title: "Lorem Inpsu",
            city: "São Paulo - SP",
            description:
              "'Lorem inpusu Lorem inpusu Lorem inpusu Lorem inpusu Lorem inpusu Lorem inpusu Lorem inpusu Lorem inpusu Lorem inpusu Lorem inpusu'",
          },
          {
            img: "",
            title: "Lorem Inpsu",
            city: "São Paulo - SP",
            description:
              "'Lorem inpusu Lorem inpusu Lorem inpusu Lorem inpusu Lorem inpusu Lorem inpusu Lorem inpusu Lorem inpusu Lorem inpusu Lorem inpusu'",
          },
          {
            img: "",
            title: "Lorem Inpsu",
            city: "São Paulo - SP",
            description:
              "'Lorem inpusu Lorem inpusu Lorem inpusu Lorem inpusu Lorem inpusu Lorem inpusu Lorem inpusu Lorem inpusu Lorem inpusu Lorem inpusu'",
          },
        ].map((i) => (
          <Col xs={12} md={4}>
            <Stack
              direction={"row"}
              spacing={2}
              sx={{ position: "relative" }}
              pt={2}
              mt={4}
            >
              <Box
                width={"100%"}
                pl="20px"
                textAlign="center"
                justifyContent={"center"}
                alignItems={"center"}
                display={"flex"}
                flexDirection={"column"}
              >
                <Box
                  style={{
                    width: "150px",
                    height: "150px",
                    backgroundColor: "#d3d3d3",
                    borderRadius: "100%",
                  }}
                />
                <Typography fontSize={20} color={"secondary"} fontWeight={700}>
                  {i.title}
                </Typography>
                <Typography fontWeight={700}>{i.city}</Typography>
                <Typography>{i.description}</Typography>
              </Box>
            </Stack>
          </Col>
        ))}
      </Row> */}
      {/*<Row*/}
      {/*  justifyContent="center"*/}
      {/*  sx={{*/}
      {/*    display: { xs: "block", md: "flex" },*/}
      {/*    mb: { md: 5, xs: 10 },*/}
      {/*    mt: { xs: 15, md: 0 },*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Col xs={12} sx={styles.boxCadastro2} mt={6}>*/}
      {/*    <Stack*/}
      {/*      direction={{ md: "row", xs: "column" }}*/}
      {/*      spacing={2}*/}
      {/*      sx={{ position: "relative" }}*/}
      {/*      pt={2}*/}
      {/*    >*/}
      {/*      <Stack*/}
      {/*        width={{ md: "300px", xs: "100%" }}*/}
      {/*        justifyContent="center"*/}
      {/*        alignItems={{ md: "start", xs: "center" }}*/}
      {/*      >*/}
      {/*        <Box ml={3} sx={styles.boxEncontrePintor2}>*/}
      {/*          <Box*/}
      {/*            sx={{*/}
      {/*              background: "#5BD3FF",*/}
      {/*              width: "200px",*/}
      {/*              height: "200px",*/}
      {/*              borderRadius: "100%",*/}
      {/*              backgroundImage: `url(${pintoImage})`,*/}
      {/*              backgroundSize: "cover",*/}
      {/*            }}*/}
      {/*          />*/}
      {/*        </Box>*/}
      {/*      </Stack>*/}
      {/*      <Box*/}
      {/*        width={"100%"}*/}
      {/*        p="10px"*/}
      {/*        pt={{ xs: 14, md: 2 }}*/}
      {/*        textAlign="center"*/}
      {/*      >*/}
      {/*        <Typography*/}
      {/*          color="secondary"*/}
      {/*          variant="h3"*/}
      {/*          fontSize={28}*/}
      {/*          fontStyle="italic"*/}
      {/*          fontWeight={700}*/}
      {/*        >*/}
      {/*          Faça já seu cadastro, amigo pintor!{" "}*/}
      {/*        </Typography>*/}
      {/*        <Typography*/}
      {/*          color="primary"*/}
      {/*          variant="h3"*/}
      {/*          fontSize={28}*/}
      {/*          fontStyle="italic"*/}
      {/*        >*/}
      {/*          O Processo é simples e rápido{" "}*/}
      {/*        </Typography>*/}

      {/*        <Stack*/}
      {/*          sx={{*/}
      {/*            bgcolor: "secondary.main",*/}
      {/*            mt: 3,*/}
      {/*            p: 1.5,*/}
      {/*            borderRadius: 6,*/}
      {/*            cursor: "pointer",*/}
      {/*          }}*/}
      {/*          onClick={onNavigate.bind(this, "/cadastro-pintor")}*/}
      {/*        >*/}
      {/*          <Typography fontSize={22} color="whiter">*/}
      {/*            <strong> Clique aqui </strong> e confira como fazer parte!{" "}*/}
      {/*          </Typography>*/}
      {/*        </Stack>*/}
      {/*      </Box>*/}
      {/*    </Stack>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
    </Container>
  );
};

const styles = {
  boxCadastro: { background: "#f2f2f2", height: "300px", borderRadius: "30px" },
  boxCadastro2: {
    background: "#f2f2f2",
    height: { md: "240px", xs: "350px" },
    borderRadius: "30px",
  },
  boxEncontrePintor: {
    position: "absolute",
    border: "solid 3px #5BD3FF",
    top: -48,
    padding: "6px",
    borderRadius: "100%",
    right: 20,
  },
  boxEncontrePintor2: {
    position: "absolute",
    border: "solid 3px #5BD3FF",
    padding: "6px",
    borderRadius: "100%",
    left: { md: 20, xs: "" },
    top: { md: -48, xs: "" },
  },
  boxFotoPintor: {
    borderRadius: "35px",
    height: { xs: "180px", md: "220px" },
    textAlign: "center",
    width: "100%",
    p: 1,
  },
  boxPintor: {
    height: "140px",
    width: "140px",
    borderRadius: "100%",
    border: "solid 3px #5BD3FF",
    top: -70,
    background: "#fff",
    position: "absolute",
  },
};

export default Home;
