import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthUser, useIsAuthenticated, useSignOut } from "react-auth-kit";

import { Drawer, Stack, useMediaQuery, useTheme, Button, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { 
  MenuOutlined, 
  WhatsApp, 
  Search, 
  School, 
  Person, 
  AccountCircle,
} from "@mui/icons-material";

import { Row } from "./Row";
import { Col } from "./Col";
import { Container } from "./Container";
import { ModalLogin } from "./ModalLogin";
import { ModalTutorial } from "./ModalTutorial";

import logo from "../assets/logo.png";

export const Header = () => {
  const theme = useTheme();
  const signOut = useSignOut();
  const [open, setOpen] = useState(false);
  const [openTutorial, setOpenTutorial] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const url = useLocation().pathname;
  const auth = useAuthUser();
  const role = auth()?.role;

  const onMobile = () => setOpenMobile(!openMobile);

  const handleLogin = () => {
    setOpen(!open);
    if (isMobile) setOpenMobile(false);
  };

  const onNavigate = (to) => {
    navigate(to);
    if (isMobile) setOpenMobile(false);
  };

  const onTutorial = () => {
    setOpenTutorial(!openTutorial);
    if (isMobile) setOpenMobile(false);
  };

  const handleWhatsApp = () => {
    window.open("https://wa.me/5511999999999", "_blank");
  };

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    signOut();
    handleClose();
    navigate("/");
  };

  const menus = [];

  const renderButtons = () => (
    <>
      <Col xs={12} md="auto">
        <MenuButton
          onClick={onNavigate.bind(this, "/")}
          url={url}
          p={1}
          justifyContent="center"
          alignItems="center"
          startIcon={<Search />}
        >
          <a
            href="#cep"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            Ache seu pintor
          </a>
        </MenuButton>
      </Col>
      {menus.map((item) => (
        <Col
          onClick={item.onClick || onNavigate.bind(this, item.url)}
          key={item.nome}
          xs={12}
          md="auto"
        >
          <MenuButton
            item={item.url}
            url={url}
            p={1}
            justifyContent="center"
            alignItems="center"
          >
            {item.nome}
          </MenuButton>
        </Col>
      ))}

      <Col onClick={onTutorial} xs={12} md="auto">
        <MenuButton
          url={url}
          p={1}
          justifyContent="center"
          alignItems="center"
          startIcon={<School />}
        >
          Como me cadastrar
        </MenuButton>
      </Col>

      {isAuthenticated() ? (
        <>
          <Col
            key={"login"}
            xs={12}
            md="auto"
          >
            <MenuButton
              item={
                role === "PINTOR"
                  ? "/pintor/galeria"
                  : "/visitante/pintor"
              }
              url={url}
              p={1}
              justifyContent="center"
              alignItems="center"
              startIcon={<AccountCircle />}
              onClick={handleProfileClick}
            >
              Perfil
            </MenuButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={() => {
                onNavigate(role === "PINTOR" ? "/pintor/galeria" : "/visitante/pintor");
                handleClose();
              }}>Meu Perfil</MenuItem>
              <MenuItem onClick={handleLogout}>Sair</MenuItem>
            </Menu>
          </Col>
          <Col onClick={handleWhatsApp} xs={12} md="auto">
            <MenuButton
              url={url}
              p={1}
              justifyContent="center"
              alignItems="center"
              startIcon={<WhatsApp />}
            >
              SAC
            </MenuButton>
          </Col>
        </>
      ) : (
        <>
          <Col onClick={handleLogin} key={"login"} xs={12} md="auto">
            <MenuButton
              url={url}
              p={1}
              justifyContent="center"
              alignItems="center"
              startIcon={<Person />}
            >
              Sou cliente
            </MenuButton>
          </Col>
          <Col
            onClick={() => {
              window.open(
                "https://www.clubepropintor.com.br",
                "_blank"
              );
            }}
            key={"login"}
            xs={12}
            md="auto"
          >
            <MenuButton
              url={url}
              p={1}
              justifyContent="center"
              alignItems="center"
              startIcon={<Person />}
            >
              Sou pintor
            </MenuButton>
          </Col>
          <Col onClick={handleWhatsApp} xs={12} md="auto">
            <MenuButton
              url={url}
              p={1}
              justifyContent="center"
              alignItems="center"
              startIcon={<WhatsApp />}
            >
              SAC
            </MenuButton>
          </Col>
        </>
      )}
    </>
  );

  return (
    <Container>
      <Row
        my={5}
        justifyContent="space-between"
        alignItems="center"
      >
        <Col justifyContent="center" alignItems="center">
          <BoxLogo onClick={onNavigate.bind(this, "/")}>
            <img src={logo} alt="logo" width={isMobile ? "100px" : "240px"} />
          </BoxLogo>
        </Col>
        {isMobile ? (
          <Col>
            <MenuOutlined fontSize="large" onClick={onMobile} />
          </Col>
        ) : (
          <Col container spacing={2} sx={{ ml: 30 }}>
            {renderButtons()}
          </Col>
        )}
      </Row>
      <ModalLogin open={open} onClose={handleLogin} />
      <ModalTutorial open={openTutorial} onClose={onTutorial} />
      <Drawer open={openMobile} onClose={onMobile}>
        <Col mt={2} p={1}>
          <Col container spacing={2}>
            {renderButtons()}
          </Col>
        </Col>
      </Drawer>
    </Container>
  );
};

const BoxLogo = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    top: "20px",
    zIndex: 20,
    position: "absolute",
    cursor: "pointer",
  },
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    alignItems: "center",
  },
}));

const MenuButton = styled(Button)(({ theme, item, url }) => ({
  borderRadius: "0.8rem",
  minWidth: "120px",
  height: "40px",
  cursor: "pointer",
  border: item === url ? "solid 2px #004f99" : "none",
  fontWeight: item === url ? 700 : 500,
  textTransform: "none",
  fontSize: "1rem",
  color: "#004f99",
  "&:hover": {
    backgroundColor: "rgba(0, 79, 153, 0.1)",
  },
  "& .MuiSvgIcon-root": {
    color: "#004f99",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: "16px",
    minWidth: "100%",
  },
}));

