import React, { useState } from "react";
import { Typography} from "@mui/material";
import { Player } from "video-react";

import { Row } from "./Row";
import { Col } from "./Col";

import { Modal } from "./Modal";

export const ModalTutorial = ({ open, onClose }) => {
  const [selectedType, setSelectedType] = useState("cliente");

  return (
    <Modal open={open} onClose={onClose} maxWidth="lg">
      <Row spacing={2}>
        <Col xs={12} md={12} container spacing={2}>
          <Col xs={12} md={7}>
            <Player
              playsInline
              poster={selectedType === "pintor" ? "/assets/Banner_03_Home_Desktop.jpg" : "/assets/Banner_Home_4.png"}
              src={`${process.env.REACT_APP_BACKEND_URL}/upload/${selectedType === "pintor" ? "pintor.mp4" : "consumidor.mp4"}`}
              style={{ borderRadius: "10px" }}
            />
          </Col>
          <Col xs={12} md={5}>
            <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
              <button 
                onClick={() => setSelectedType("cliente")}
                style={{
                  padding: "10px 20px",
                  backgroundColor: selectedType === "cliente" ? "#004f99" : "transparent",
                  color: selectedType === "cliente" ? "#fff" : "#004f99",
                  border: selectedType === "cliente" ? "none" : "1px solid #004f99",
                  borderRadius: "5px",
                  cursor: "pointer"
                }}
              >
                Sou Cliente
              </button>
              <button 
                onClick={() => setSelectedType("pintor")}
                style={{
                  padding: "10px 20px",
                  backgroundColor: selectedType === "pintor" ? "#3cc7cf" : "transparent",
                  color: selectedType === "pintor" ? "#fff" : "#3cc7cf",
                  border: selectedType === "pintor" ? "none" : "1px solid #3cc7cf",
                  borderRadius: "5px",
                  cursor: "pointer"
                }}
              >
                Sou Pintor
              </button>
            </div>
            <Typography variant="h1" color="secondary">
              Video Tutorial
            </Typography>
            <Typography variant="h2" color="primary" fontWeight={700}>
              {selectedType === "pintor" ? "Sou Pintor" : "Sou Cliente"}
            </Typography>
            <Typography>
              {selectedType === "pintor" 
                ? "Amigo pintor, está querendo se cadastrar no Ache seu pintor, mas está com dificuldades? Fique tranquilo a Sherwin-Williams preparou um tutorial completo para facilitar a sua vida! Veja o vídeo ao lado cadastre-se! E ache novos clientes!"
                : "Ei, você está com dificuldades para achar o seu profissional ideal de pintura? A sherwin-Williams preparou um tutorial para te ajudar a navegar no site e achar o pintor ideal para a sua reforma, assista o vídeo ao lado e siga seus passos!"}
            </Typography>
          </Col>
        </Col>
      </Row>
    </Modal>
  );
};
