import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSignIn } from "react-auth-kit";

import { Stack, Typography } from "@mui/material";
import { Lock } from "@mui/icons-material";

import { useApi } from "../../hooks/useApi";
import { API_URL } from "../../config/apiUrl";

import {
  Container,
  Row,
  Col,
  Button,
  InputMask,
  Input,
  Radio,
  Select,
} from "../../components";
import api from "../../services/api";
import { useUser } from "../../hooks/useUser";
import { useLocation } from 'react-router-dom';

import banner from "../../assets/Banner_Cadastro_Validacao.jpg";

const CadastroVisitante = () => {
  const [loadingCep, setLoadingCep] = useState(false);
  const [acepted, setAcepted] = useState([]);
  const query = new URLSearchParams(useLocation().search);


  const navigate = useNavigate();
  const signIn = useSignIn();
  const { saveUser } = useUser();

  const onAcepted = (item) => {
    const newArr = acepted.filter((v) => v !== item);
    newArr.push(item);
    setAcepted(newArr);
  };

  const [{ data, loading }, fetch] = useApi(
    { ...API_URL.VISITANTE },
    { manual: true }
  );

  useEffect(() => {
    if (data) {
      signIn({
        token: data.access_token,
        expiresIn: data.expiresIn,
        authState: data.usuario,
        tokenType: "Bearer",
      });
      saveUser(data.usuario);
      navigate("/visitante/pintor");
    }
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = (values) => {
	  values.facebookId = query.get('facebookId') || null;
	  values.googleAuthId = query.get('googleAuthId') || null;

    fetch({ data: values });
  };

	// setNome(query.get('name') || null);
	// setEmail(query.get('email') || null);

	// facebookId: query.get('facebookId') || null,
	// 	googleAuthId: query.get('googleAuthId') || null,
	// 	codigo_cadastro: query.get('codigo_cadastro'),

  const onCep = async (setFieldValue, e) => {
    setLoadingCep(true);

    try {
      const cep = e.target.value.replace(/^\d{4}$/, "");
      const { data } = await api.get(`/cep?cep=${cep}`);

      setFieldValue("rua", data.endereco);
      setFieldValue("bairro", data.bairro);
      setFieldValue("cidade", data.cidade);
      setFieldValue("estado", data.uf);
    } catch (error) {}

    setLoadingCep(false);
  };

  const opcoesComoFicouSabendo = [
    { value: "INSTAGRAM", label: "Instagram" },
    { value: "FACEBOOK", label: "Facebook" },
    { value: "RADIO", label: "Rádio" },
    { value: "GOOGLE", label: "Google" }
  ];

  return (
    <Container loading={loading || loadingCep} imgs={[banner]}>
      <Formik
        initialValues={{}}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <Row spacing={2} mt={{ md: 6, xs: 0 }} mb={6} sx={styles.container}>
              <Col xs={12}>
                <Stack p={3} spacing={2}>
                  <Typography
                    color="secondary"
                    variant="h4"
                    fontWeight={700}
                    fontStyle="italic"
                  >
                    Seu cadastro é essencial para garantirmos a segurança de
                    clientes e profissionais
                  </Typography>

                  <Typography
                    variant="caption"
                    fontSize={18}
                    fontStyle="italic"
                  >
                    <Typography
                      color="secondary"
                      variant="caption"
                      fontWeight={700}
                      fontSize={18}
                    >
                      O processo é rápido e fácil:{" "}
                    </Typography>
                    complete seu perfil para entrar em contato com pintores da
                    sua região agora mesmo!
                  </Typography>
                </Stack>
              </Col>
              <Col xs={12} md={7}>
                <Field
                  as={Input}
                  name="nome"
                  placeholder="Nome completo"
                  fullWidth
                  required
                  size="large"
                />
              </Col>
              <Col xs={12} md={5}>
                <Field
                  as={InputMask}
                  mask="999.999.999-99"
                  name="cpf"
                  placeholder="CPF"
                  fullWidth
                  required
                  size="large"
                />
              </Col>
              <Col xs={12} md={4}>
                <Field
                  as={InputMask}
                  mask="(99) 99999-9999"
                  name="celular"
                  placeholder="Celular"
                  fullWidth
                  required
                  size="large"
                />
              </Col>
              <Col xs={12} md={4}>
                <Field
                  as={Input}
                  name="email"
                  placeholder="E-mail"
                  fullWidth
                  required
                  size="large"
                />
              </Col>

              <Col xs={12} md={4}>
                <Field
                  as={InputMask}
                  mask="99/99/9999"
                  name="dataNascimento"
                  placeholder="Data de nascimento"
                  fullWidth
                  required
                  size="large"
                />
              </Col>
              <Col xs={12} md={12}>
                <Field
                  as={InputMask}
                  name="cep"
                  mask={"99999-999"}
                  placeholder="CEP"
                  fullWidth
                  required
                  size="large"
                  onBlur={onCep.bind(this, setFieldValue)}
                />
              </Col>

              <Col xs={12} md={9}>
                <Field
                  as={Input}
                  name="rua"
                  placeholder="Endereço"
                  fullWidth
                  size="large"
                />
              </Col>
              <Col xs={12} md={3}>
                <Field
                  as={Input}
                  name="numero"
                  placeholder="Número"
                  fullWidth
                  required
                  size="large"
                />
              </Col>

              <Col xs={12} md={6}>
                <Field
                  as={Input}
                  name="bairro"
                  placeholder="Bairro"
                  fullWidth
                  size="large"
                />
              </Col>
              <Col xs={12} md={6}>
                <Field
                  as={InputMask}
                  name="complemento"
                  placeholder="Complemento"
                  fullWidth
                  size="large"
                />
              </Col>

              <Col xs={12} md={6}>
                <Field
                  as={Input}
                  name="cidade"
                  placeholder="Cidade"
                  required
                  fullWidth
                  size="large"
                />
              </Col>
              <Col xs={12} md={6}>
                <Field
                  as={Input}
                  name="estado"
                  placeholder="Estado"
                  required
                  fullWidth
                  size="large"
                />
              </Col>

              <Col xs={12} md={12}>
                <Field
                  as={Select}
                  name="comoFicouSabendo"
                  label="Como ficou sabendo?"
                  menu={opcoesComoFicouSabendo}
                  fullWidth
                  required
                />
              </Col>

              <Col xs={12} md={12}>
                <Stack direction={"row"} spacing={2} alignItems="center">
                  <Lock color="secondary" fontSize="large" />
                  <Typography variant="h5" fontWeight={700} color={"secondary"}>
                    Crie sua senha contendo 8-20 caracteres incluindo letras e
                    números.
                  </Typography>
                </Stack>
              </Col>

              <Col xs={12} md={6}>
                <Field
                  as={Input}
                  name="senha"
                  type="password"
                  placeholder="Senha*"
                  fullWidth
                  required
                  size="large"
                />
              </Col>

              <Col xs={12} md={6}>
                <Field
                  as={Input}
                  name="confirmSenha"
                  type="password"
                  placeholder="Confirmar Senha Senha*"
                  fullWidth
                  required
                  size="large"
                />
              </Col>
              <Col xs={12} md={12}>
                <Radio
                  label={
                    "Aceito os termos de protocolo de privacidade e cookies."
                  }
                  alignItems="center"
                  onChange={onAcepted.bind(this, "1")}
                  color="secondary"
                />
              </Col>
              <Col xs={12} md={12}>
                <Radio
                  label={
                    "Quero receber mensagens e novidades por WhatsApp e e-mail."
                  }
                  alignItems="center"
                  onChange={onAcepted.bind(this, "2")}
                  color="secondary"
                />
              </Col>
              <Col xs={12} md={12}>
                <Radio
                  label={
                    "Concordo em compartilhar meus dados para uso exclusivo da Smart Tecnologia em vendas S.A, sendo direito da Smart transferência destas informações para uso da Sherwin-Williams e empresas afiliadas"
                  }
                  alignItems="center"
                  onChange={onAcepted.bind(this, "3")}
                  color="secondary"
                />
              </Col>

              <Col xs={12} md={12}>
                <Radio
                  label={
                    <>
                      Aceito os termos de uso do pintor. Que estão disponíveis{" "}
                      <a href="/termo-contratante" target="_blank">
                        clicando aqui
                      </a>
                    </>
                  }
                  alignItems="center"
                  onChange={onAcepted.bind(this, "4")}
                  color="secondary"
                />
              </Col>

              <Col xs={12} md={12}>
                <Button
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={!(Number(acepted.length) === 4)}
                >
                  Cadastrar
                </Button>{" "}
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

const styles = {
  container: {
    background: "#f2f2f2",
    borderRadius: "22px",
    padding: "1rem",
  },
};

export default CadastroVisitante;
